import { Injectable, Signal, WritableSignal, inject, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { translate } from '@jsverse/transloco';
import { SnackbarTypes } from './common.types';
import { CustomizedSnackbarComponent } from '@auth-library/shared/components/customized-snackbar/customized-snackbar.component';
import { ProductType } from '@auth-library/models/product.model';
import { ActivatedRoute } from '@angular/router';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    private _snackBar: MatSnackBar = inject(MatSnackBar);
    productType = ProductType;
    private _activatedRoute = inject(ActivatedRoute);
    fromQueryParamValue : Signal<string | null | undefined> = toSignal(this._activatedRoute.queryParams.pipe(map((queryParams)=>queryParams['from'])));
    selectedApplication = this.productType.bp; /** set bp as default value */

    /** patterns */
    emailPattern = '^[a-zA-Z0-9._%+-]{1,64}@([a-zA-Z0-9_%+-]{2,}(\\.){1}){1,5}([a-zA-Z]){2,6}$';
    passwordPattern = '^(?=.*[0-9]{1})(?=.[a-z]*)(?=.*[A-Z]{1}).*$';
    phonePattern = '^((00)|[+])[1-9][0-9]{8,14}$|^(0[1-9][0-9]{8,9})$';


    /** snackbar message type */
    successMessage = 'success-message-snackbar';
    errorMessage = 'error-snackbar';
    infoMessage = 'info-message-snackbar';

    showSnackBar({ message, snackBarType, disableTranslate }: { message: string, snackBarType: SnackbarTypes, disableTranslate?: boolean }): void {

        this._snackBar.openFromComponent(CustomizedSnackbarComponent, {

            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: [snackBarType], // .success-message-snackbar .error-snackbar .info-message-snackbar
            duration: 5000,
            data: {
                snackBarType,
                msg: disableTranslate ? message : translate(message),
            }
        });
    }

    /** query params */
  buildQueryParams(params: { [key: string]: any }): string {
      const queryParams = Object.keys(params)
        .filter(key => params[key] !== null && params[key] !== '')
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
        .join('&');
        return queryParams ? '?' + queryParams : '';
      }
  }
